<template>
  <v-card class="ma-3 px-3 pt-3" style="background-color:transparent!important; overflow-x:hidden;" 
          elevation="0" outlined >
    
    <v-toolbar elevation="0" color="transparent">
    <v-toolbar-title style="font-size:1.8em">
      <v-icon class="mb-3">mdi-chart-areaspline</v-icon>
      Stats trafic
    </v-toolbar-title>
      <v-spacer/>

      <v-btn small icon outlined class="mx-2 mb-3" @click="refreshChartVisitors()">
        <v-icon small>mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>
  
      <ChartVisitors :height="300" :width="100"/>
  
    <template v-if="dataSet != null">
      <br>
      <v-btn  small  color="white" light elevation="0"
              class="mb-2 mr-2 text-left" @click="showAllLine(true)">
              <v-icon small>mdi-asterisk</v-icon> Tous
      </v-btn>
      <v-btn  small  color="black" dark elevation="0"
              class="mb-2 mr-2 text-left" @click="showAllLine(false)">
              <v-icon class="mr-1" x-small>mdi-circle</v-icon> Aucun
      </v-btn>
    
      <template v-for="(line, l) in dataSet">
        <v-btn  :key="l" small :text="line.hidden" :outlined="!line.hidden"
                :color="line.pointBackgroundColor" 
                class="mb-2 mr-2 text-left" @click="showLine(l)">
                  {{ line.label }}
        </v-btn>
      </template>


      <!-- <template v-for="(line, l) in dataSet">
        <v-btn  :key="l" small :text="line.hidden" :outlined="!line.hidden"
                :color="line.pointBackgroundColor" 
                class="mb-2 mr-2 text-left" @click="showLine(l)">
                  {{ line.label }}
        </v-btn> -->

        <v-simple-table dense class="my-5 mx-1" height="390">
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <template v-for="(line, l) in dataSet[0].data">
                  <th :key="'header'+l" class="text-left px-0 pr-1">
                    {{ getDateLbl(l, dataSet[0].data.length) }}
                  </th>
                </template>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, l) in dataSet" v-if="!line.hidden"
                  :key="line.label" :class="line.pointBackgroundColor+'--text'"
              >
                <td :style="'color:'+line.pointBackgroundColor">{{ line.label }}</td>
                <template v-for="(data, d) in line.data">
                  <td :style="'color:'+line.pointBackgroundColor" class="px-0" :key="'date'+d">{{ data }}</td>
                </template>
                <td>
                  <v-btn  x-small icon :text="line.hidden" :outlined="!line.hidden"
                          :color="line.pointBackgroundColor" 
                           @click="showLine(l)">
                            <v-icon x-small>mdi-close</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        
      <!-- </template> -->


    </template>
  
  </v-card>
</template>


<style></style>

<script>
//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

import ChartVisitors from '@/components/admin/charts/ChartVisitors.vue'

export default {
  name: 'admin-block-stat-visitors',
  components: { ChartVisitors },
  data: () => ({
    adminList: [],
    dataSet: null
  }),
  async mounted(){
    this.$root.$off('initDataSet').$on('initDataSet', (data) => { 
        this.dataSet = data
        console.log("data", data)
    })
  },
  methods: {    
    refreshChartVisitors(){
      this.$root.$emit('refreshChartVisitors')
    },
    showLine(line){
      this.$root.$emit('showLine', line)
    },
    showAllLine(bool){
      this.$root.$emit('showAllLine', bool)
    },
    getDateLbl(l, length){
      let d = new Date()
      d.setDate(d.getDate() - length + l + 1)
      let date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate()
      let month = d.getMonth() < 10 ? "0" + d.getMonth() : d.getMonth()
      return date + "/" + month
    }
  },
  computed: {    
  },
  filters: {
  },
  watch: {
  }, 
}
</script>
